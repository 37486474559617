export const routes = [
    {
        path: '/admin-sign',
        name: "Login",
        component: () =>
            import ('../views/Auth.vue'),
    },
    {
        path: "/clients_payment",
        component: () =>
            import ("../views/ClientPayment.vue"),
        name: "GuestPage",
    },
    {
        path: "/company/:id",
        component: () =>
            import ("../views/CompanyDetail.vue"),
        name: "Registration",
    },

    {
        path: "/admin-login",
        component: () =>
            import ("../views/Auth.vue")
    },

    {
        path: "/kassa-login",
        component: () =>
            import ("../views/KassaLogin.vue")
    },


    {
        path: "/",
        component: () =>
            import ("../views/GuestPage.vue"),
        name: "GuestPage",
        /////
    },
    {
        path: "/company_add",
        component: () =>
            import ("../views/Registration.vue"),
        name: "Registration",
    },
    {
        path: "/bonuses",
        component: () =>
            import ("../views/Bonuses.vue"),
        name: "Bonuses",
    },
    {
        path: "/profile",
        component: () =>
            import ("../views/Profile.vue"),
        name: "Registration",
    },
    {
        path: "/guest/:id",
        component: () =>
            import ("../views/Guest.vue"),
        name: "Guest",
    },
    {
        path: "/cash",
        component: () =>
            import ("../views/CashInterface.vue"),
        name: "CashInterface",
    },


    {
        path: '/admin_panel',
        component: () =>
            import ('../views/AdminControl.vue'),
        children: [
            {
                path: "",
                component: () =>
                    import ("../views/ProductControl.vue"),
                name: "ProductControl",
            },
            {
                path: "/category_control",
                component: () =>
                    import ("../views/CategoryControl.vue"),
                name: "CategoryControl",
            },
            {
                path: "/stock",
                component: () =>
                    import ("../views/StockPage.vue"),
                name: "StockPage",
            },
            {
                path: "/sales",
                component: () =>
                    import ("../views/SalePage.vue"),
                name: "SalePage",
            },
            {
                path: "/analytic",
                component: () =>
                    import ("../views/Analytic.vue"),
                name: "SalePage",
            },
            {
                path: "/bonuses",
                component: () =>
                    import ("../views/BonusInterface.vue"),
                name: "BonusInterface",
            },
            {
                path: "/loans",
                component: () =>
                    import ("../views/LoanInterface.vue"),
                name: "BonusInterface",
            },
        ]
    },
    {
        path: '/main',
        component: () =>
            import ('../views/Main.vue'),
        children: [
            {
                path: "/",
                component: () =>
                    import ("../views/Profile.vue"),
                name: "Sign",
            },
            {
                path: "/settings",
                component: () =>
                    import ("../views/ProfileSettings.vue"),
                name: "Sign",
            },
            {
                path: "/result",
                component: () =>
                    import ("../views/Result.vue"),
                name: "Sign",
            },
            {
                path: "/register",
                component: () =>
                    import ("../views/UserRegistration.vue"),
                name: "Sign",
            },
        ],


    },

    {
        path: '/admin',
        component: () =>
            import ('../components/AdminPanel.vue'),

        children: [{
            path: "/",
            component: () =>
                import ("../views/Auth.vue"),
            name: "Sign",
        },

            {
                path: "/orders",
                component: () =>
                    import ("../components/Orders.vue"),
                name: "Orders",
            },
            {
                path: "/profile",
                component: () =>
                    import ("../components/Profile.vue"),
                name: "Registration",
            },


        ]
    },
    {
        path: "/mailing",
        component: () =>
            import ("../views/Mailing.vue"),
        name: "Mailing",
    },


]